export function hide(elem){
    elem.classList.add("hide");
}

export function show(elem){
    elem.classList.remove("hide");
}

export function fadeInOut(elem){
    elem.classList.add("fadeInOut")
    elem.addEventListener("transitionend", () => {
        setTimeout(() => {elem.classList.remove("fadeInOut")}, 5000)
    })
}