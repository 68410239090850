import { submitForm } from "../helpers/submitForm"

const googleMap = document.querySelector(".google-map")

if(googleMap){
    const iframe = googleMap.querySelector("iframe")
    iframe.onload = function(){
        if(googleMap){
            googleMap.classList.add("loaded")
        }
    }
}

const contactUsForm = document.querySelector("#ContactUsForm");

if(contactUsForm){
    contactUsForm.addEventListener( "submit", (event) => {
        // Prevent default submit behaviour
        event.preventDefault();
        // Run our own function instead
        submitForm(contactUsForm,"/umbraco/Surface/Form/submitForm");
    } );
}

const findABrokerForm = document.querySelector("#FindABrokerForm");

if(findABrokerForm){
    findABrokerForm.addEventListener( "submit", (event) => {
        // Prevent default submit behaviour
        event.preventDefault();
        // Run our own function instead
        submitForm(findABrokerForm,"/umbraco/Surface/Form/findABrokerForm");
    } );
}