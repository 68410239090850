import {show, hide} from "./show-hide";

const asides = document.querySelectorAll("aside");

asides.forEach(function(index){
        // Get uls from each aside
        const groups = index.querySelectorAll("ul");
        // Group headings
        const groupHeadings = index.querySelectorAll(".menu-label");
        // Create an array from each aside's lis
        const array = Array.from(index.querySelectorAll('li'))
        // Show the first 3
        const onloadArray = array.slice(0,3);
        onloadArray.forEach(function(elem){
            const groupHeading = elem.closest('ul').previousElementSibling;
            // Show slices / first 3 array items
            show(elem);
            // Show group headings if they contain visible list items
            if (groupHeading.classList.contains("menu-label")){
                show(groupHeading);
            }
        });
        // Show all list items on click and hide button
        if(index.querySelector(".load-more-button") != null){
            const loadMoreButton = index.querySelector(".load-more-button");
            loadMoreButton.addEventListener("click", e => {
                array.forEach(li => show(li));
                groupHeadings.forEach(elem => show(elem));
                hide(loadMoreButton);
            })
        }
    }
);