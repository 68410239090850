import { submitForm } from '../helpers/submitForm'
import { show, hide } from '../components/show-hide'

const complaintsForm = document.querySelector("#complaintsForm");

const steps = document.querySelectorAll("form .step")
let activeBreadcrumb =  document.querySelector(".form-breadcrumb.active")

steps.forEach(function(step, index) {
    const stepInputs =  step.querySelectorAll(".input-group input, .input-group textarea, .input-group select, .radio-button input")
    const stepInputsArray = Array.from(stepInputs)
    let nextButton = step.querySelector("button.next-button")
    const prevButton = step.querySelector("button.prev-button")
    const submitButton = step.querySelector("#submit")
    
    stepInputsArray.forEach(input => {
        input.addEventListener("change", () => {
            // If all fields are valid enable the next button
            if(nextButton){
                stepInputsArray.every(isValid)
                ? nextButton.disabled = false
                : nextButton.disabled = true
            }
            if(submitButton){
                stepInputsArray.every(isValid) 
                ? submitButton.disabled = false
                : submitButton.disabled = true
            }
        })
    });

    step.addEventListener('click', function(e){
        if(e.target == nextButton || e.target == prevButton){
            hide(step)
            if(activeBreadcrumb){
                updateBreadcrumb(e)
            }
        }
        if(e.target == nextButton){
            show(step.nextElementSibling)
        }
        else if(e.target == prevButton){
            show(step.previousElementSibling)
        }
    })

    function updateBreadcrumb(e){
        if (e.target == nextButton){
                activeBreadcrumb.classList.remove("active")
                activeBreadcrumb = activeBreadcrumb.nextElementSibling
                activeBreadcrumb.classList.add("active")
        }
        if (e.target == prevButton){
            activeBreadcrumb.classList.remove("active")
            activeBreadcrumb = activeBreadcrumb.previousElementSibling
            activeBreadcrumb.classList.add("active")
        }
    }
    
});

const isValid = (input) => input.checkValidity()

if(complaintsForm){
    complaintsForm.addEventListener( "submit", (event) => {
        // Prevent default submit behaviour
        event.preventDefault();
        // Run our own function instead
        submitForm(complaintsForm,"/umbraco/Surface/Form/complaintsForm");
    } );
}