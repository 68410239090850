import { notifications } from '../components/forms'
import { show, hide, fadeInOut } from '../components/show-hide'

export function submitForm(form, postURL) {

    const loaderOverlay = form.querySelector('.loader-overlay');
    const activeModal = document.querySelector(".modal.is-active")
    const submitButton = form.querySelector("input[type=submit]")

    const XHR = new XMLHttpRequest();

    // Bind the FormData object and the form element
    const FD = new FormData(form);

    XHR.addEventListener( "loadstart", () => {
        loaderOverlay.classList.add('loading');
    });

    // Define what happens on successful data submission
    XHR.addEventListener( "load", function(event) {
        if (event.target.status == 200){
            fadeInOut(notifications.success)
            // Check if modal is open and close it
            
            if(activeModal){
                setTimeout(() => {
                    activeModal.classList.remove("is-active");
                }, 1500)
            }
        }
        else {
            fadeInOut(notifications.error)
        }
        loaderOverlay.classList.remove('loading');
    } );

    // Define what happens in case of error
    XHR.addEventListener( "error", function(event) {
        show(notifications.error)
        setTimeout(() => {hide(notifications.error)}, 5000)
    } );

    // Set up our request
    XHR.open( "POST", postURL );

    // The data sent is what the user provided in the form
    XHR.send( FD );
    form.reset();
}