const openModalElems = document.querySelectorAll(".modal-open")
const closeModalElems = document.querySelectorAll(".modal-close, .modal-background")
const html = document.querySelector("html")

openModalElems.forEach(element => {
    element.addEventListener("click", e => {
        let modal = element.dataset.modal
        modal = document.querySelector("#" + modal)
        openModal(modal)
    })
});

closeModalElems.forEach(element => {
    element.addEventListener("click", e => {
        closeModal()
    })
});

function openModal(modal){
    modal.classList.add("is-active")
    html.classList.add("is-clipped")
}

function closeModal(){
    const activeModal = document.querySelector(".modal.is-active")
    activeModal.classList.remove("is-active")
    html.classList.remove("is-clipped")
}