const inputs = document.querySelectorAll(".input-group input, .input-group textarea, .input-group select")

function validateField(input){
    input.value 
        ? input.classList.add("active") 
        : input.classList.remove("active")
    // Checks validity and sets state
    input.validity.valid
        ? setValid(input)
        : setInvalid(input)
    input.reportValidity()
}

function setValid(input){
    input.classList.remove("invalid");
}

function setInvalid(input){
    input.classList.add("invalid");
}

inputs.forEach(function(input){
    const inputBorder = document.createElement("div");
    inputBorder.classList.add("input-border");
    input.parentNode.append(inputBorder);
    
    // Numbers
    if(input.type == "number"){
        input.addEventListener("keypress", e => {
            const keyValue = e.key;
            // If the input is empty and the key pressed is "0" nothing is printed
            if (!e.target.value && e.key == 0) {
                e.preventDefault();
            } else {
                // If the key pressed is not a number or a period, nothing is printed
                if (!/[0-9.]/.test(keyValue)) {
                    e.preventDefault();
                }
            }
        })
    }
    input.addEventListener("change", e => {
        validateField(input)
    })
});

window.onload = function(){
    // Validates inputs on page load if cached
    inputs.forEach(function(input){
        // Validates fields with values only
        if(input.value){
            input.reportValidity()
            validateField(input)
        }
    })
}