export const notifications = {
    success: document.querySelector('.notification.is-success'),
    error: document.querySelector('.notification.is-danger'),
}

const inputs = document.querySelectorAll('input', 'textarea')

if(inputs){
    inputs.forEach(input => {
        input.addEventListener('input', () => {
            input.setCustomValidity("");
            input.classList.remove("invalid")
        })
        input.addEventListener('change', () => {
            // General error class
            if(!input.checkValidity()){
                input.classList.add("invalid")
                // Tel
                if(input.type == "tel"){
                    input.setCustomValidity("Please enter in a valid phone number");
                }
                // Email
                if(input.type == "email"){
                    input.setCustomValidity("Please enter a valid email address");
                }
            }
        })
    })
}