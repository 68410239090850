const { show, hide } = require("./show-hide")

if(document.querySelectorAll('.tab').length > 0){
    // URL Params
    const queryString = window.location.search
    const searchParams = new URLSearchParams(queryString)
    const tabOnLoad = searchParams.get('tab')
    //
    const tabs = document.querySelectorAll('.tab')
    const tabContentArray = document.querySelectorAll('.tab-content')
    let activeTab = document.querySelector('.tab.active')
    let activeTabContent = document.querySelector('.tab-content:not(.hide)')
    // If a tab is included in the URL
    if(tabOnLoad){
        if(activeTab){
            hide(activeTabContent)
            activeTab.classList.remove("active")
        }
        activeTab = document.querySelector("#tab-" + tabOnLoad)
        activeTabContent = document.querySelector("#" + tabOnLoad)
        show(activeTabContent)
        activeTab.classList.add("active")
    }
    // If no active tab is set
    if (!activeTab){
        activeTab = tabs[0]
        activeTab.classList.add("active")
    }
    if (!activeTabContent){
        activeTabContent = tabContentArray[0]
        activeTabContent.classList.remove("hide")
    }

    tabs.forEach(function(tab){
        tab.addEventListener("click", e => {
            const tabContent = document.querySelector("#" + tab.dataset.tab)
            if(tabContent.classList.contains("hide")) { 
                activeTab.classList.remove("active")
                show(tabContent)
                hide(activeTabContent)
                activeTabContent = tabContent
                activeTab = tab  
                tab.classList.add("active")
            }
        });
    });

    // Meet the team
    const meetTheTeam = document.querySelector(".meet-the-team")
    if(meetTheTeam){
        const teamMember = window.location.hash.substring(1)
        const accordionOnLoad = document.querySelector("#" + tabOnLoad + " ." + teamMember)
        accordionOnLoad.classList.add("active")
        accordionOnLoad.scrollIntoView()
    }
    
}