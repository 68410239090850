import {show, hide} from "../components/show-hide";
import { submitForm } from "../helpers/submitForm"

const sections = document.querySelectorAll(".tab-content");

sections.forEach(function(index){
    const posts = Array.from(index.querySelectorAll(".post"));
    const maxPosts = posts.length;
    const loadMoreButton = index.querySelector(".load-more-button");
    // set default post number to 8
    let postsNumber = 8;
    // show initial posts
    let visiblePosts = posts.slice(0,postsNumber);
    visiblePosts.forEach(post => show(post))
    if(loadMoreButton != null){
        loadMoreButton.addEventListener("click",e => {
            postsNumber = postsNumber + 8;
            // create new array from original
            visiblePosts = posts.slice(0,postsNumber);
            visiblePosts.forEach(post => show(post))
            if(postsNumber >= maxPosts){
                hide(loadMoreButton);
            }
        });
    }
});

const subscribeToNewsletter = document.querySelector("#subscribeForm");

if(subscribeToNewsletter){
    subscribeToNewsletter.addEventListener( "submit", (event) => {
        // Prevent default submit behaviour
        event.preventDefault();
        // Run our own function instead
        submitForm(subscribeToNewsletter,"/umbraco/Surface/Form/subscribeForm");
    } );
}

